import React from 'react';
import { connect } from 'react-redux';

import AboveTheFoldOnlyServerRender from '../shared/components/above-the-fold-only-server-render';
import ETRFingerPrint from '../shared/components/etr-fingerprint';
import ScripLoader from '../page/components/script-loader';
import InsideChat from '../page/components/inside-chat';

const getGtmUrl = tagName => `https://www.googletagmanager.com/gtm.js?id=${tagName}`;

class ThirdPartyTags extends React.PureComponent {
    static defaultProps = {
        marketing: [
            'https://www.googletagmanager.com/gtm.js?id=GTM-N72GKFZ',
            'https://nexus.ensighten.com/hp/udlmarketing/Bootstrap.js',
        ],
        analytics: [],
        googleAnalytics: [],
        abtesting: [],
    };

    render() {
        const {
            location,
            analytics,
            abtesting,
            googleAnalytics,
            marketing,
            enableInsideChat,
            insideChatUrl,
            enableETRFingerPrint = true,
            scriptTags = [],
        } = this.props;
        const { query = {} } = location || {};
        const { __disableThirdParty, __3PBuffer } = query;

        if (__disableThirdParty === '*') {
            return null;
        }

        let analyticsBuffer = 0;
        let analyticsLoadStrategy = 'load';

        const idleBuffer = isNaN(query.__3PBuffer) ? 2000 : __3PBuffer * 1;
        const disableMarketing = __disableThirdParty === 'marketing';
        const disableABTest = __disableThirdParty === 'optly';
        const disableAnalytics = __disableThirdParty === 'udl';

        return (
            <React.Fragment>
                {!disableAnalytics && (
                    <AboveTheFoldOnlyServerRender
                        renderOn={analyticsLoadStrategy}
                        skip={true}
                        idleBuffer={analyticsBuffer}
                    >
                        {analytics.map(url => (
                            <ScripLoader key={url} url={url} />
                        ))}
                    </AboveTheFoldOnlyServerRender>
                )}
                {!disableMarketing && (
                    <AboveTheFoldOnlyServerRender renderOn={'idle'} skip={true} idleBuffer={idleBuffer}>
                        {marketing.map(url => (
                            <ScripLoader key={url} url={url} once={true} />
                        ))}
                    </AboveTheFoldOnlyServerRender>
                )}
                {!disableABTest && (
                    <AboveTheFoldOnlyServerRender renderOn={'idle'} skip={true}>
                        {abtesting.map(url => (
                            <ScripLoader key={url} url={url} once={true} />
                        ))}
                    </AboveTheFoldOnlyServerRender>
                )}
                {/* !!! if buffer is not provided in Merch UI it's set to null value (which does not work with default
                     value assignment during ojb property destructuring) */}
                {Array.isArray(googleAnalytics) &&
                    googleAnalytics.map(({ tagName, renderOn = 'idle', buffer }) => (
                        <AboveTheFoldOnlyServerRender
                            key={getGtmUrl(tagName)}
                            renderOn={renderOn}
                            idleBuffer={isNaN(buffer) ? idleBuffer : buffer * 1}
                        >
                            <ScripLoader url={getGtmUrl(tagName)} once={true} />
                        </AboveTheFoldOnlyServerRender>
                    ))}
                {enableInsideChat && (
                    <AboveTheFoldOnlyServerRender renderOn={'idle'} skip={true} idleBuffer={idleBuffer}>
                        <InsideChat scriptUrl={insideChatUrl} />
                    </AboveTheFoldOnlyServerRender>
                )}
                <AboveTheFoldOnlyServerRender renderOn={'idle'} skip={true} idleBuffer={idleBuffer}>
                    <ETRFingerPrint enabled={enableETRFingerPrint} />
                </AboveTheFoldOnlyServerRender>
                {/**TODO: The rest of the tags can just be moved into this tagsArray and each controlled with their own lazyload settings */}
                {scriptTags.map(tag => {
                    if (!tag || !tag.src) {
                        return null;
                    }
                    const { renderOn = 'idle', skip = true, buffer = idleBuffer, src, once = true } = tag;
                    return (
                        <AboveTheFoldOnlyServerRender key={src} renderOn={renderOn} skip={!!skip} idleBuffer={buffer}>
                            <ScripLoader url={src} once={once} />
                        </AboveTheFoldOnlyServerRender>
                    );
                })}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    //possible tempory for controlling this optimization from page component
    const { router, thirdPartyTags } = state;
    const { location } = router || {};
    let { marketing, abtesting, analytics, googleAnalytics, enableMoxie, ...restTags } = thirdPartyTags;
    //TODO: temporarily force defaults for these if someone accidently deletes them all from DB
    marketing = !marketing || marketing.length === 0 ? undefined : marketing;
    analytics = !analytics || analytics.length === 0 ? undefined : analytics;
    googleAnalytics = !googleAnalytics || googleAnalytics.length === 0 ? undefined : googleAnalytics;
    return { location, marketing, abtesting, analytics, googleAnalytics, enableMoxie, ...restTags };
};

export default connect(mapStateToProps, null)(ThirdPartyTags);
