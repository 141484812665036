import React from 'react';

import ButtonIcon from './button-icon';
import CartPreview from '../../../utility/components/cart-preview/cart-preview';
import { useHeaderSettings } from '../../../hooks/useHeaderFooter';
import { RenderOnMount } from '../../../shared/components/above-the-fold-only-server-render';

import './header-cart.less';

/** Presentational compotent shared by desktop and mobile headers */
export function HeaderCart({ localState, enableCartFlyout, enableHeaderState = true }) {
    const headerSettings = useHeaderSettings();
    const { disableCart } = headerSettings;
    if (enableHeaderState && disableCart) {
        return null;
    }
    return (
        <div className="header-cart">
            <CartPreview stickyCart localState={localState} enableCartFlyout={enableCartFlyout}>
                {({ count, onHover, onClick, isNewVersion, showPreview }) => {
                    return (
                        <ButtonIcon
                            onClick={onClick}
                            buttonClass={`header-cart-button${isNewVersion ? ' new-version' : ''}${
                                showPreview ? ' minicart-open' : ''
                            }`}
                            onMouseEnter={onHover}
                            icon="icon-cart"
                            ariaLabel="Shopping Cart"
                        >
                            <RenderOnMount>
                                {count && (
                                    <span className="cart-badge-container">
                                        <span className="cart-badge">{count}</span>
                                    </span>
                                )}
                            </RenderOnMount>
                        </ButtonIcon>
                    );
                }}
            </CartPreview>
        </div>
    );
}
export default HeaderCart;
