import memoize from '../memoize';
import { Helpers } from '../helpers';

import { StoreAppAPI } from '../storeapp-api-client';
import { getCTOLink } from './cto';
import { setPriceDifference } from './util';

const storeAppAPI = new StoreAppAPI();
const MAX_RETRIES = 1;

//TEMP: this should be done server side eventually,
//especially if app pages store to display private store specific content
const setpStoreID = memoize(pStoreID => {
    try {
        window.pStoreID = pStoreID;
        if (document && document.querySelectorAll) {
            document.querySelectorAll('.header a').forEach(e => {
                if (e.href && e.href.indexOf('us-en/shop') > -1) {
                    e.href = Helpers.addUrlParam(e.href, { pStoreID }, true);
                }
            });
        }
    } catch (e) {
        //pass
    }
});

const getSubscriptionCancellationFee = (price, term, rate = 0.1) => {
    let multiplier = 1;
    //if term is monthly calculate fee for the year, else assume term and price is annual
    if (term === 'Monthly') {
        multiplier *= 12;
    }
    return price * multiplier * rate;
};

//TODO: clean up stockMessage if not needed
export const getStockMessaging = (stock, inventoryMessage) => {
    let stockMessage;
    let stockStatus;
    const isLimited = /limited stock/gi.test(inventoryMessage);
    if (!isLimited && (stock === undefined || stock > 25)) {
        stockMessage = `In stock`;
        stockStatus = 'green';
    } else if (isLimited || (stock <= 25 && stock > 0)) {
        stockMessage = `Only ${stock} Left!`;
        stockStatus = 'orange';
        if (stock <= 10) {
            stockStatus = 'red';
        }
    } else if (stock === 0) {
        stockMessage = 'Currently out of stock';
        stockStatus = 'grey';
    }
    return { stockMessage, stockStatus };
};

/**
 * Get derived price props for stellar price components
 * @returns
 */
const getPriceProps = (obj, priceSettings) => {
    try {
        const { productType: prdClass, violatorMsg, priceblockMsg, price, lPrice } = obj;
        const {
            separateSellingPrice = 'List price when products purchased separately:',
            freeShippingMessage = 'Free Shipping',
        } = priceSettings || {};
        const productType = (prdClass || '').toLowerCase();
        const isBundle = productType === 'bundle';
        if (!productType) {
            return {};
        }
        return {
            productType,
            //sometimes there are bundles that don't actually provide any discount so make sure there the list price is different than price
            bundle: isBundle && price !== '0.00' && price !== lPrice && { tooltip: { hidden: true } },
            violatorMessage: violatorMsg,
            //TODO: remove violatorMsg fallback here after ETR deployment in April 2023
            customSaleText: priceblockMsg || violatorMsg,
            translations: {
                freeShippingMessage,
                ...(isBundle && { separateSellingPrice }),
            },
        };
    } catch (e) {
        return {};
    }
};

class ServicesAPI {
    constructor(apiConfig, http) {
        this.apiConfig = apiConfig;
        this.http = http;
        //initial cache of request, sort of a weird memoize for us to test fetching service data prior to hydration
        this.initialCache = {};
        this.prefetchAborted = false;
        //TODO: might be able to pick this from ETR service response, but for now this hardcoded
        this.currency = 'USD';
        this.locale = 'us-EN';
        this.priceSettings = {};
        this.timestamp = Date.now();
    }

    setPriceSettings = priceSettings => {
        this.priceSettings = priceSettings;
    };

    logError(error, info) {
        storeAppAPI.logger.log({
            message: `${error.name}: ${error.message}`,
            info,
            level: 'error',
        });
    }

    getTimeStamp() {
        //if timestamp is older than 15 minutes, reset it
        const current = Date.now();
        if (current - this.timestamp > 900000) {
            this.timestamp = current;
        }
        return this.timestamp;
    }

    parsePriceObj(priceObj) {
        const { currency, locale, priceSettings } = this;
        const { tierMsg, tierPrice } = priceObj || {};
        const derivedPrice = {
            regularPrice: priceObj.price.toFloat(),
            salePrice: priceObj.lPrice.toFloat(),
            inCartPrice: priceObj.cpf,
            tierPrice,
            tierMsg: tierMsg || (tierPrice ? 'PRIVATE STORE EXCLUSIVE PRICE' : undefined),
            isccf: priceObj.isccf,
            gmPoints: priceObj.gmPoints,
            sku: priceObj.partNumber,
            itemId: priceObj.productId,
            jTierDis: priceObj.jTierDis * 1 > 0 ? priceObj.jTierDis : undefined,
            jPromMsg: priceObj.jPromMsg,
            currency,
            locale,
            cpf: priceObj.cpf,
            ...getPriceProps(priceObj, priceSettings),
        };
        return setPriceDifference(derivedPrice);
    }

    abortPrefetch = () => {
        this.prefetchAborted = true;
    };

    setPrefetchCache = data => {
        if (!this.prefetchAborted) {
            this.initialCache = data;
        }
    };

    //TODO: Deprecate this in favor of returning in the useProductPrice hook. Could be used by third parties that use the appActions.fetchPrices call though
    getCTAText = (invObj, isOOS) => {
        try {
            const { productType: prdClass, backOrderFlag } = invObj;

            let text = 'Add to cart';
            if (isOOS) {
                text = 'Out of stock';
            }
            return {
                customizeText: ['CTO', 'GIFTCARD'].includes(prdClass) ? 'Customize & buy' : undefined,
                ctaText: text,
            };
        } catch (e) {
            return {};
        }
    };

    parseInvObj(invObj) {
        const {
            productId,
            stock,
            ponboStock,
            productType: prdClass,
            partNumber,
            fullImages,
            inventoryMessage,
            plAndFcetSbbrnd,
            prodName,
            isComingSoonSku,
            backOrderFlag,
            Subscription_Term: subscriptionTerm,
            SUBSCRIPTION_DISC_TXT_WEB: subscriptionDescription,
            SUBSCRIPTION_DISC_TXT_CC: subscriptionDescriptionCallCenter,
            STOSKUType: stoSKUType,
            preordermaxqty,
            hideAddToCart,
            subType,
            deliveryDate,
            Devdependency: deviceDependency,
        } = invObj;
        const productType = (prdClass || '').toLowerCase();
        const preOrder = invObj.preOrderFlag;
        const stockAvailable = preOrder ? ponboStock : stock;
        const isOOS = stockAvailable === 0 && prdClass !== 'CTO';
        const { ctaText, customizeText } = this.getCTAText(invObj, isOOS);
        const ctoLink = productType && productType.toLowerCase() === 'cto' ? getCTOLink(productId, 1) : undefined;
        //TODO: remove fallback message after PDP AB test
        const { stockStatus, stockMessage: fallBackMessage } = getStockMessaging(stockAvailable, inventoryMessage);
        const isSubscription = Helpers.isSubscription(invObj);

        let plcode, facet_subbrand;
        try {
            [plcode, facet_subbrand] = plAndFcetSbbrnd.split(':');
        } catch (e) {}

        return {
            stock,
            ponboStock,
            //PRODUCT-800: do not display stock message for back order skus
            stockMessage: !backOrderFlag ? inventoryMessage || fallBackMessage : undefined,
            stockStatus,
            invAvaDate: invObj.invAvaDate,
            preOrder,
            ctaText,
            customizeText,
            ctoLink,
            isOOS,
            sku: partNumber,
            plcode,
            facet_subbrand,
            plAndFcetSbbrnd,
            fullImages,
            prodName,
            productId,
            isComingSoonSku,
            backOrderFlag,
            subscriptionTerm,
            subscriptionDescription,
            subscriptionDescriptionCallCenter,
            isSubscription,
            salePriceSuffix: isSubscription ? Helpers.getSubFrequencyText(subscriptionTerm) : undefined,
            stoSKUType,
            preordermaxqty,
            hideAddToCart: hideAddToCart === 'YES',
            subType,
            //sometimes productType only comes from inventory object and not price. Handle it here
            productType,
            deliveryDate,
            deviceDependency,
        };
    }

    mergeItemData(currItems, itemPrice, itemInventory) {
        const newItems = itemPrice || itemInventory || [];
        //loops through newItems and merge into existing
        return newItems.reduce((result, item) => {
            let itemObj;
            if (itemPrice) {
                itemObj = this.parsePriceObj(item);
                itemObj.order = item.order;
            }

            if (itemInventory) {
                itemObj = this.parseInvObj(item);
            }
            itemObj.itemId = item.productId;

            result[item.productId] = Object.assign(result[item.productId] || {}, itemObj);
            return result;
        }, currItems || {});
    }

    mergeServiceData(etrSVCData) {
        let inventoryData = etrSVCData.inventoryData || [];
        let priceData = etrSVCData.priceData || [];
        let serviceData = {};
        let pStoreID;

        //Handle edge where amt is not defined
        if (etrSVCData.cartData && etrSVCData.cartData.items) {
            etrSVCData.cartData.items = etrSVCData.cartData.items.map(i => {
                if (!i.amt) {
                    i.amt = {};
                    this.logError({ name: 'HPService', message: 'Cart item missing amt object' }, JSON.stringify(i));
                }
                return i;
            });

            try {
                window.guxAnalytics = window.guxAnalytics || {};
                window.guxAnalytics.cartId = etrSVCData.cartData.id || window.guxAnalytics.cartId;
            } catch (e) {}
        }
        //merge price and inventory data
        //combine in paralell (order not garaunteed)
        for (var i = 0; i < Math.max(inventoryData.length, priceData.length); i++) {
            let invObj = i < inventoryData.length ? inventoryData[i] : undefined;
            let priceObj = i < priceData.length ? priceData[i] : undefined;

            if (priceObj) {
                if ((priceObj.productType || '').toLowerCase() === 'category') {
                    //model price does not have inventory info associated with it. So just set as is
                    let key = priceObj.productId.split('model_').pop();
                    serviceData[key] = this.parsePriceObj(priceObj);
                } else {
                    let svcData = serviceData[priceObj.partNumber] || {};
                    let currItems = svcData.items;
                    serviceData[priceObj.partNumber] = Object.assign(svcData, {
                        ...this.parsePriceObj(priceObj),
                        items: this.mergeItemData(currItems, priceObj.items, null),
                    });
                }
            }
            let invData;
            if (invObj) {
                let svcData = serviceData[invObj.partNumber] || {};
                let currItems = svcData.items;
                invData = this.parseInvObj(invObj);
                serviceData[invObj.partNumber] = Object.assign(svcData, {
                    ...invData,
                    items: this.mergeItemData(currItems, null, invObj.items),
                });
            }
            //set subscription description with dynamic prace
            try {
                const {
                    subscriptionDescription,
                    subscriptionDescriptionCallCenter,
                    stoSKUType,
                    salePrice,
                    isccf,
                    subscriptionTerm,
                } = serviceData[invObj.partNumber] || {};
                if (stoSKUType === 'SUBSCRIPTION' && subscriptionDescription && salePrice) {
                    let dynamicDescription = isccf ? subscriptionDescriptionCallCenter : subscriptionDescription;
                    dynamicDescription = dynamicDescription.replace(
                        /(\&lt\;SUBS_PROD_PRICE\&gt\;|\<SUBS_PROD_PRICE\>)/gi,
                        Helpers.formatCurrency(salePrice, '&#36;', true),
                    );
                    dynamicDescription = dynamicDescription.replace(
                        /(\&lt\;SUBS_STATE_FEE\&gt\;|\<SUBS_STATE_FEE\>)/gi,
                        //Monthly Recurring amount x 12 x 10%
                        Helpers.formatCurrency(
                            getSubscriptionCancellationFee(salePrice, subscriptionTerm),
                            '&#36;',
                            true,
                        ),
                    );
                    delete serviceData[invObj.partNumber].subscriptionDescriptionCallCenter;
                    serviceData[invObj.partNumber].subscriptionDescription = dynamicDescription;
                } else {
                    delete serviceData[invObj.partNumber].subscriptionDescription;
                }
            } catch (e) {}
        }

        delete etrSVCData.inventoryData;
        delete etrSVCData.priceData;
        etrSVCData.serviceData = serviceData;

        //add bool flag to indicate if user is logged in
        etrSVCData.profileData = etrSVCData.profileData || {};
        etrSVCData.profileData.personData = etrSVCData.profileData.personData || {};
        etrSVCData.profileData.personData.isLoggedIn = !!etrSVCData.profileData.personData.userTier;
        pStoreID =
            etrSVCData.profileData.pstoreId ||
            (etrSVCData.profileData.homeUrl || '').split('pStoreID=')[1] ||
            (etrSVCData.profileData.manageMyDeviceUrl || '').split('pStoreID=')[1];
        etrSVCData.profileData.personData.pStoreID = pStoreID;
        if (pStoreID) {
            setpStoreID(pStoreID);
        }

        return etrSVCData;
    }

    hpServices(action, catentryId = [], modelId = [], retry = 0, cache) {
        // include site params with add to cart specific params
        const params = Object.assign(
            {
                _: this.getTimeStamp(),
                action: action || 'cupids',
                catentryId: catentryId.join(',') || '',
                modelId: modelId.join(',') || '',
                pstoreId: Helpers.getSearch(window.location.search).pStoreID,
            },
            this.apiConfig.siteInfo,
        );

        return this.http
            .get('/HPServices', { params })
            .then(resp => {
                const respType = typeof resp.data;
                const { status, request } = resp;
                if (respType !== 'object' || status * 1 !== 200) {
                    if (retry < MAX_RETRIES) {
                        return new Promise((resolve, reject) => {
                            setTimeout(() => {
                                this.hpServices(action, catentryId, modelId, ++retry).then(resolve).catch(reject);
                            }, 500);
                        });
                    } else {
                        const message = `HPService responded ${resp.status} with type: ${respType} value: ${
                            resp.data
                        } after ${retry + 1} attempts`;
                        const error = new Error(message);
                        error.catentryId = catentryId;
                        error.requestUrl = request.responseURL;
                        throw error;
                    }
                }
                resp.data = this.mergeServiceData(resp.data);
                if (cache) {
                    this.setPrefetchCache(resp.data);
                }
                return resp;
            })
            .catch(error => {
                this.logError({ name: 'HPService', message: error.message }, error);
                error.catentryId = catentryId;
                return Promise.reject(error);
            });
    }

    //pick from prefetch cache if available
    pickFromCache = (action, ids) => {
        const { cartData, profileData, serviceData, storeData } = this.initialCache;
        if (action === 'cus' && cartData && profileData && storeData) {
            delete this.initialCache.cartData;
            delete this.initialCache.profileData;
            delete this.initialCache.storeData;
            return Promise.resolve({
                status: 200,
                data: {
                    cartData,
                    profileData,
                    storeData,
                },
            });
        } else if (action === 'ipd' && serviceData) {
            const cachedPrices = Object.keys(serviceData).reduce((r, sku) => {
                const prd = serviceData[sku];
                if (ids.indexOf(prd.itemId) > -1) {
                    r[sku] = prd;
                }
                return r;
            }, {});
            //if all prices available in cache return them
            if (Object.keys(cachedPrices).length === ids.length) {
                return Promise.resolve({
                    status: 200,
                    data: {
                        //return all service data returned instead of those matching the ids provided, this is to support bundle item prices
                        serviceData,
                    },
                });
            }
        }
        return this.hpServices(action, ids);
    };

    fetchProfileAndCart() {
        return this.pickFromCache('cus');
    }

    fetchModelPriceAndInventory(ids) {
        return this.hpServices('ipd', [], ids);
    }

    fetchPriceAndInventory(ids) {
        return this.pickFromCache('ipd', ids);
    }

    fetchPrices(ids) {
        return this.hpServices('p', ids);
    }

    fetchInventory(ids) {
        return this.hpServices('i', ids);
    }

    fetchCart() {
        return this.hpServices('c');
    }

    fetchProfile() {
        return this.hpServices('u');
    }

    fetchStore() {
        return this.hpServices('s');
    }
}

export default ServicesAPI;
